@import "./assets/styles/bootstrap/scss/bootstrap";
@import "./assets/fonts";
@import "./assets/breeze-base.css";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "./assets/wfm_variables";
@import "./assets/styles/primeng-bootstrap/theme.css";
@import "../node_modules/primeng/resources/primeng.min.css";
@import "./assets/styles/checkbox";
@import "./assets/dialog";
@import "./assets/calendar";
@import "./assets/popover";
@import "./assets/toast";
@import "./assets/styles/toolbar";
@import "./assets/grid";
@import "./assets/styles/multiselect";
@import "./assets/styles/dropdown";
@import "./assets/progressbar";
@import "./assets/tablets";
@import "./assets/styles/input-switch";
@import "./assets/styles/buttons";
@import "./assets/styles/radio";
@import "./assets/styles/embedded";
@import "./assets/styles/tooltip";
@import "./assets/styles/input-number";
@import "./assets/styles/validation-tooltips";
@import "./assets/styles/tabs";
@import "./assets/styles/cxone/week-picker";
@import "./assets/styles/table";
// @import "~ag-grid/src/styles/ag-grid.scss";

@import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
@import 'ag-grid-community/styles/ag-theme-balham.css'; // Optional theme CSS

//@import "../node_modules/ag-grid-community/dist/styles/ag-grid.css";
//@import "../node_modules/ag-grid-community/dist/styles/ag-theme-balham.css";
//@import "../node_modules/ag-grid-community/dist/styles/ag-theme-balham/";

// Customize the look and feel of the grid with Sass variables
// Up-to-date list of variables is available in the source code: https://github.com/ag-grid/ag-grid/blob/latest/src/styles/ag-theme-fresh.scss
$icons-path: "~ag-grid-community/src/styles/icons/";
$font-family: "Open Sans Regular";
$font-size: 14px;
$header-background-color: white;
$border-color: #B8C5CC;
$panel-background-color: #E1EAF2;
$primary-color: blue;
$row-height: 45px;
$header-height: 45px;
$icon-size: 14px;
$selected-color: #F2F5F7;
$header-icon-size: 16px;
// $font-family: $font-family-base;

.ag-header {
  //border-bottom: 1px solid grey;
  .ag-header-cell-text {
    text-transform: uppercase;
    font-size: 10pt;
    font-weight: 800;
    text-overflow: ellipsis;
    overflow:hidden;
  }
}
/*
.ag-theme-balham {
  @include ag-theme-balham((
    balham-active-color: blue,
  ));

  .ag-header {
    font-family: "Open Sans";
    color: #98A2B3
  }
}*/

html {
  margin: 0;
  /*height: 100%;*/
}

body {
  margin: 0;
  height: 100%;
  overflow: hidden;
  font-family: OpenSans, sans-serif;

}

/*
.fill-space{
  width: 100%;
  height: 100%;
}
*/

.icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  fill: #93a4aa;
}

p.message-unstyled {
  padding: .8em 0 0 .6em;
}

.btn.btn-secondary {
  color: #6b7984;
  background-color: #fff;
  border-color: #dae2e8;
  box-shadow: 0px 2px 0px 0px #dae2e8;
}

.btn.btn-secondary:hover,
.btn-secondary:hover:focus:not(:active) {
  background-color: #fff;
  border-color: #6b8ea8;
  box-shadow: 0px 2px 0px 0px #6b8ea8;
}

.wfm-modal {
  z-index: 1060;
  .modal-dialog{
    max-width: fit-content;
  }
  .modal-header {
    justify-content: flex-start;
    border-bottom: 1px solid #d0d5d9;
    align-items: flex-end;
    button.close {
      color: #afc1cf;
    }
  }

  .modal-body {
    background-color: #E9F1F5;
  }
  .system-error-icon {
    fill: #FA3F40;
    width: 20px;
    height: 20px;
    display: inline-block;
  }
  // button.btn {
  //   margin-left: 5px;
  //   margin-right: 5px;
  // }
}

@mixin container-no-scroll {
  overflow: hidden;
}

@mixin icon-common {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  @include container-no-scroll;
}

@mixin wfm-input-label-show($textColor) {
  display: block;
  z-index: 10;
  top: -0.7rem;
  left: 0.7rem;
  background-color: white;
  color: $textColor;
  font-size: 1rem;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open>.dropdown-toggle.btn-primary {
  background-color: #298fd8;
  border-color: #298fd8;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show>.btn-primary.dropdown-toggle {
  background-color: #1c639c;
  border-color: #1c639c;
}

input {
  &::placeholder {
    color: $gray-600;
  }
  &.wfm-form-control {
    border-radius: 0;
    &:focus {
      box-shadow: none;
      &+div {
        border-left: none;
        &>.input-group-text {
          border-color: $input-focus-border-color;
        }
      }
    }
    &.is-invalid {
      &:focus {
        box-shadow: none;
      }
    }
    transition: none;
  }
}

.wfm-form-section-header {
  padding: 1rem 0 1rem 0;
}

.input-label.sub-label{
  display:block;
  margin-top: -5px;
  text-transform: none;
}

.wfm-input-group {
  position: relative;
  label {
    position: absolute;
    display: none;
    padding-left: 10px;
    padding-right: 10px;
  }
  input {
    &.has-value+label {
      @include wfm-input-label-show($gray-500);
    }
    &:focus {
      &+label {
        @include wfm-input-label-show($blue);
        &.is-invalid {
          color: $form-feedback-invalid-color;
          &~.wfm-input-password-icon {
            .input-group-text {
              border-color: $form-feedback-invalid-color;
            }
          }
        }
        &.is-valid {
          color: $form-feedback-valid-color;
          &~.wfm-input-password-icon {
            .input-group-text {
              border-color: $form-feedback-valid-color;
            }
          }
        }
      }
      &::placeholder {
        color: transparent;
      }
      &~.wfm-input-password-icon {
        .input-group-text {
          border-color: $blue;
        }
      }
    }
    &:valid {
      &:focus {
        box-shadow: none;
      }
      &+div {
        border-color: $form-feedback-valid-color;
      }
    }
  }
  .wfm-input-with-icon {
    border-right: 0;
  }
  .wfm-input-password-icon {
    cursor: pointer;
    z-index: 10;
    position: absolute;
    height: 100%;
    &>.input-group-text {
      background-color: transparent;
      border-left: none;
      padding: 6px;
    }
  }
  .input-group-text {
    &.is-invalid {
      border-color: $form-feedback-invalid-color;
    }
    &.is-valid {
      border-color: $form-feedback-valid-color;
    }
  }
}

div {
  &.app-container {
    &.wfm-login-form-container {
      padding-top: 0;
      width: 100vw;
      max-width: 100vw;
      height: 100vh;
      margin: 0;
      background: url("assets/images/background-pattern.svg") no-repeat;
      background-size: cover;
    }
  }
}

.wfm-login-form-container {
  .login-container {
    height: 98vh;
    h1 {
      font-size: 6rem;
      @include media-breakpoint-down(lg) {
        font-size: 2.5rem;
      }
      @include media-breakpoint-down(md) {
        font-size: 2rem;
      }
      @include media-breakpoint-down(sm) {
        font-size: 1.4rem;
      }
      @include media-breakpoint-down(xs) {
        font-size: 1.2rem;
      }
    }
  }
}

.page-title-container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}
.page-title-container .page-header{
  flex: 0 1 auto;
  overflow: hidden;
}
.page-title-container .text-right{
  flex: 1 0 auto;
  width: auto;
}

.page-header {
  margin: 0;
  margin-bottom: 20px;
}

.ngx-datatable {
  &.wfm-table {
    .datatable-header {
      color: $table-header-text-color;
      background-color: $white;
      border-bottom: 1px solid $border-color;
      .header-cell {
        padding: 15px;
        .datatable-header-cell-template-wrap {
          cursor: pointer;
        }
        &:hover {
          .resize-handle {
            border-right: 1px solid $border-color;
          }
        }
      }
    }
    .datatable-body {
      .datatable-body-row {
        background-color: white;
        padding: 5px;
        border-bottom: 1px solid $border-color;
        .wfm-table-cell {
          .cell-value-truncated {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }
      }
    }
  }
}

.sortable:not(.sort-active) {
  span {
    &.sort-btn {
      &:before {
        @include iconClass;
        content: "\e995";
      }
    }
  }
}

.wfm-icon {
  font-size: 2.5rem;
}

.wfm-nav-item {
  line-height: 38px;
  &.wfm-with-icon {
    i {
      margin-right: 20px;
      vertical-align: middle;
    }
  }
}

datatable-body-row {
  cursor: pointer;
}

button {
  &[disabled] {
    cursor: not-allowed;
  }
}

.toast-container {
  .toast-success {
    width: 342px;
    &:before {
      @include icon-common;
      content: '\e927';
      font-size: 2rem;
      vertical-align: middle;
      margin-right: 16px;
    }
    background-image: initial;
    padding-top: 0;
    padding-bottom: 0;
    background-color: #23762D;
    height: 70px;
    color: white;
    padding-left: 16px;
    .toast-close-button {
      position: absolute;
      float: initial;
      right: 16px;
      top: 18px;
      background-color: transparent;
      border: none;
      opacity: 1;
      &:hover {
        color: white;
        opacity: 1;
      }
      &:focus {
        color: white;
        opacity: 1;
      }
      &:before {
        @include icon-common;
        content: '\e935';
        font-size: 1.4rem;
        vertical-align: middle;
      }
      span {
        display: none;
      }
    }
    .toast-message {
      display: inline;
      line-height: 70px;
    }
  }
}

.wfm-top-bar {
  background-color: #298FD8;
  .navbar-brand {
    margin-left: 10px;
    padding-top: 8px;
  }
  .navbar-nav {
    .nav-item {
      margin-left: 15px;
      a {
        margin-top: 5px;
        &.dropdown-toggle {
          &::after {
            display: none;
          }
        }
      }
    }
  }
}

.nav-pills {
  .nav-link {
    &.active {
      background-color: $wfm-active-link-bg;
      color: black;
      i {
        color: #B917D6;
      }
      border-left: 5px solid #B8C4CC;
    }
  }
}

.ag-theme-balham {
  .ag-header-cell {
    &::after {
      border: none;
    }
    &.only-action {
      div {
        &.ag-header-cell-label {
          width: 100%;
          span {
            &.ag-header-cell-text {
              width: 100%;
            }
          }
        }
      }
    }
  }
  .ag-header-group-cell {
    &::after {
      border: none;
    }
  }
}

.ag-icon {
  height: 24px;
  width: 24px;
  background-size: 24px 24px;
}

/**
  * Icons
  **/

.ag-checkbox-unchecked {
  .ag-icon {
    &.ag-icon-checkbox-unchecked {
      cursor: pointer;
      &:hover {
        background-image: url("assets/images/icons/rectangle_hover.svg");
      }
      background-image: url("assets/images/icons/rectangle.svg");
    }
  }
}

.ag-selection-checkbox {
  cursor: pointer;
}

.ag-header-select-all {
  cursor: pointer;
}

.full-width-table {
  width: 100%;
  height: 100%;
}

.wrap-text {
  white-space: normal
}

.table-list {
  .ag-root {
    border: none;
  }
}

.search-box {
  background-color: #F7F8FA;
  padding: 20px;
}


.vertical-align-middle{
  display: flex;
  align-items: center;
}
.overlay-panel{
  background: rgba(255, 255, 255, 0.8);
  top: 0;
  bottom: 0;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;
}
.breeze .no-text-decoration{
  text-transform: none;
}
