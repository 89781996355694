$font-size-base: 1.4rem;
$font-family-base: "Open Sans Regular";
$wfm-border-radius: 4px;
$wfm-active-link-bg: #F2F5F7;

$card-border-radius: $wfm-border-radius;
$input-border-radius: $wfm-border-radius;
$input-border-radius-lg: $wfm-border-radius;
$input-border-radius-sm: $wfm-border-radius;
$form-group-margin-bottom: 2rem;
$input-focus-border-color: #007cbe;
$btn-border-radius: $wfm-border-radius;
$btn-border-radius-lg: $wfm-border-radius;
$btn-border-radius-sm: $wfm-border-radius;
$input-btn-padding-y: 1rem;
$input-height: 48px;
$bg-color: #eaf1f7;
$black: #222f37;
$white: #ffffff;
$red: #d82132;
$green: #00a65a;
$grey: #8c95a5;
$blue: #007cbe;
$header-text-color: #404040;
$table-header-text-color: #8c95a5;
$border-color: #dae2e8;
$dark-border-color: #c1bebe;
$font-generic: 14px "opensans-regular";
$header-height: 60px;
$btn-box-shadow-color: #105286;
$column-header-cell-color: #8c95a5;
$row-hover-color:#f5f8fa
