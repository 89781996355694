@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?i7h9kd');
  src:  url('fonts/icomoon.eot?i7h9kd#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?i7h9kd') format('truetype'),
    url('fonts/icomoon.woff?i7h9kd') format('woff'),
    url('fonts/icomoon.svg?i7h9kd#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-workflow:before {
  content: "\e900";
}
.icon-Icons_svg_activity-codes:before {
  content: "\e901";
}
.icon-Icons_svg_daily-rules:before {
  content: "\e902";
}
.icon-Icons_svg_generate-schedule:before {
  content: "\e903";
}
.icon-Icons_svg_import-forecast-data:before {
  content: "\e904";
}
.icon-Icons_svg_my-schedule:before {
  content: "\e905";
}
.icon-Icons_svg_my-space:before {
  content: "\e906";
}
.icon-Icons_svg_schedule-manager:before {
  content: "\e907";
}
.icon-Icons_svg_scheduler:before {
  content: "\e908";
}
.icon-Icons_svg_schedulin-unit:before {
  content: "\e909";
}
.icon-Icons_svg_setup:before {
  content: "\e90a";
}
.icon-Icons_svg_skills:before {
  content: "\e90b";
}
.icon-Icons_svg_users:before {
  content: "\e90c";
}
.icon-Icons_svg_weekly-rules:before {
  content: "\e90d";
}
.icon-Icons_outline_bell:before {
  content: "\e90e";
}
.icon-employee-groups:before {
  content: "\e90f";
}
.icon-event-mapping:before {
  content: "\e910";
}
.icon-outline_rta:before {
  content: "\e911";
}
.icon-form-mgmnt:before {
  content: "\e912";
}
.icon-copy:before {
  content: "\e913";
}
.icon-delete:before {
  content: "\e914";
}
.icon-edit:before {
  content: "\e915";
}
.icon-paste:before {
  content: "\e916";
}
.icon-note:before {
  content: "\e917";
}
.icon-arrow-down:before {
  content: "\e918";
}
.icon-arrow-left:before {
  content: "\e919";
}
.icon-arrow-right:before {
  content: "\e91a";
}
.icon-attachment:before {
  content: "\e91b";
}
.icon-change-template:before {
  content: "\e91c";
}
.icon-date-time:before {
  content: "\e91d";
}
.icon-dropdown:before {
  content: "\e91e";
}
.icon-help:before {
  content: "\e91f";
}
.icon-hyperlink:before {
  content: "\e920";
}
.icon-image:before {
  content: "\e921";
}
.icon-label:before {
  content: "\e922";
}
.icon-manage-forms:before {
  content: "\e923";
}
.icon-manage-template:before {
  content: "\e924";
}
.icon-more:before {
  content: "\e925";
}
.icon-multi-choices:before {
  content: "\e926";
}
.icon-publish:before {
  content: "\e927";
}
.icon-redo:before {
  content: "\e928";
}
.icon-save:before {
  content: "\e929";
}
.icon-scoring:before {
  content: "\e92a";
}
.icon-section:before {
  content: "\e92b";
}
.icon-text:before {
  content: "\e92c";
}
.icon-text-area:before {
  content: "\e92d";
}
.icon-undo:before {
  content: "\e92e";
}
.icon-unpublish:before {
  content: "\e92f";
}
.icon-yes-no-radio:before {
  content: "\e930";
}
.icon-Search:before {
  content: "\e931";
}
.icon-Headphones:before {
  content: "\e932";
}
.icon-Quality_planner:before {
  content: "\e933";
}
.icon-Time_Picker:before {
  content: "\e934";
}
.icon-My_Dashboard:before {
  content: "\e935";
}
.icon-Close:before {
  content: "\e936";
}
.icon-Filter:before {
  content: "\e937";
}
.icon-Current_points:before {
  content: "\e938";
}
.icon-Smily_happy:before {
  content: "\e939";
}
.icon-Smily_sad:before {
  content: "\e93a";
}
.icon-Add_more:before {
  content: "\e93b";
}
.icon-remove_icon:before {
  content: "\e93c";
}
.icon-Text_bold:before {
  content: "\e93d";
}
.icon-Text_centeraligh:before {
  content: "\e93e";
}
.icon-Text_italic:before {
  content: "\e93f";
}
.icon-Text_leftaligh:before {
  content: "\e940";
}
.icon-Text_rightaligh:before {
  content: "\e941";
}
.icon-Text_size:before {
  content: "\e942";
}
.icon-Text_underline:before {
  content: "\e943";
}
.icon-yesno:before {
  content: "\e944";
}
.icon-Add_another:before {
  content: "\e945";
}
.icon-MyEvaluations:before {
  content: "\e946";
}
.icon-MyInteractions:before {
  content: "\e947";
}
.icon-MyTasks:before {
  content: "\e948";
}
.icon-plan_Monitoring:before {
  content: "\e949";
}
.icon-performance_Monitoring:before {
  content: "\e94a";
}
.icon-Logic:before {
  content: "\e94b";
}
.icon-open:before {
  content: "\e94c";
}
.icon-close:before {
  content: "\e94d";
}
.icon-sidebar_toggle:before {
  content: "\e94e";
}
.icon-check:before {
  content: "\e94f";
}
.icon-history_data:before {
  content: "\e950";
}
.icon-forecaster:before {
  content: "\e951";
}
.icon-Avg_Score:before {
  content: "\e952";
}
.icon-Consumption_Rate:before {
  content: "\e953";
}
.icon-Distribution_Rate:before {
  content: "\e954";
}
.icon-Prev_time:before {
  content: "\e955";
}
.icon-Status:before {
  content: "\e956";
}
.icon-Search2:before {
  content: "\e957";
}
.icon-expired:before {
  content: "\e958";
}
.icon-draft:before {
  content: "\e959";
}
.icon-new:before {
  content: "\e95a";
}
.icon-comment:before {
  content: "\e95b";
}
.icon-Error_message:before {
  content: "\e95c";
}
.icon-callibration:before {
  content: "\e95d";
}
.icon-timeoff_requests:before {
  content: "\e95e";
}
.icon-requests:before {
  content: "\e95f";
}
.icon-Rename:before {
  content: "\e960";
}
.icon-running:before {
  content: "\e961";
}
.icon-scheduled:before {
  content: "\e962";
}
.icon-declined:before {
  content: "\e963";
}
.icon-active:before {
  content: "\e964";
}
.icon-stopped:before {
  content: "\e965";
}
.icon-failed:before {
  content: "\e966";
}
.icon-approved:before {
  content: "\e967";
}
.icon-succeeded:before {
  content: "\e968";
}
.icon-manager_setup:before {
  content: "\e969";
}
.icon-status_skipped_call:before {
  content: "\e96a";
}
.icon-zoom_in:before {
  content: "\e96b";
}
.icon-zoom_out:before {
  content: "\e96c";
}
.icon-expand:before {
  content: "\e96d";
}
.icon-layout-bottom:before {
  content: "\e96e";
}
.icon-layout-left:before {
  content: "\e96f";
}
.icon-layout-right:before {
  content: "\e970";
}
.icon-layout-top:before {
  content: "\e971";
}
.icon-map:before {
  content: "\e972";
}
.icon-minimize:before {
  content: "\e973";
}
.icon-modal:before {
  content: "\e974";
}
.icon-move:before {
  content: "\e975";
}
.icon-status_awaiting:before {
  content: "\e976";
}
.icon-cut:before {
  content: "\e977";
}
.icon-duplicate:before {
  content: "\e978";
}
.icon-shift_bidding:before {
  content: "\e979";
}
.icon-new-folder:before {
  content: "\e97a";
}
.icon-folder:before {
  content: "\e97b";
}
.icon-new-page:before {
  content: "\e97c";
}
.icon-page:before {
  content: "\e97d";
}
.icon-minus:before {
  content: "\e97e";
}
.icon-plus:before {
  content: "\e97f";
}
.icon-function:before {
  content: "\e980";
}
.icon-action-group:before {
  content: "\e981";
}
.icon-conditions:before {
  content: "\e982";
}
.icon-new-instance:before {
  content: "\e983";
}
.icon-instance:before {
  content: "\e984";
}
.icon-reference-project:before {
  content: "\e985";
}
.icon-new-reference-project:before {
  content: "\e986";
}
.icon-variables:before {
  content: "\e987";
}
.icon-new-variables:before {
  content: "\e988";
}
.icon-office-word:before {
  content: "\e989";
}
.icon-office:before {
  content: "\e98a";
}
.icon-office-excel:before {
  content: "\e98b";
}
.icon-office-outlook:before {
  content: "\e98c";
}
.icon-toolbar:before {
  content: "\e98d";
}
.icon-as-text:before {
  content: "\e98e";
}
.icon-splitter_h:before {
  content: "\e98f";
}
.icon-info:before {
  content: "\e990";
}
.icon-warning:before {
  content: "\e991";
}
.icon-rectangle:before {
  content: "\e992";
}
.icon-elypse:before {
  content: "\e993";
}
.icon-polygon:before {
  content: "\e994";
}
.icon-callout:before {
  content: "\e995";
}
.icon-sort:before {
  content: "\e996";
}
.icon-sort_descending:before {
  content: "\e997";
}
.icon-newlock:before {
  content: "\e998";
}
.icon-generate_forecast:before {
  content: "\e999";
}
.icon-system_info:before {
  content: "\e99a";
}
.icon-system_error:before {
  content: "\e99b";
}
.icon-system_warning:before {
  content: "\e99c";
}
.icon-system_success:before {
  content: "\e99d";
}
.icon-status_awaiting_your:before {
  content: "\e99e";
}
.icon-status_canceled:before {
  content: "\e99f";
}
.icon-status_on_track:before {
  content: "\e9a0";
}
.icon-status_off_track:before {
  content: "\e9a1";
}
.icon-status_claimed:before {
  content: "\e9a2";
}
.icon-grid_view:before {
  content: "\e9a3";
}
.icon-list_view:before {
  content: "\e9a4";
}
.icon-add_above:before {
  content: "\e9a5";
}
.icon-reward:before {
  content: "\e9a6";
}
.icon-add_participants:before {
  content: "\e9a7";
}
.icon-add_below:before {
  content: "\e9a8";
}
.icon-lock:before {
  content: "\e9a9";
}
.icon-smiley:before {
  content: "\e9aa";
}
.icon-preview:before {
  content: "\e9ab";
}
.icon-view:before {
  content: "\e9ac";
}
.icon-feedback:before {
  content: "\e9ad";
}
.icon-Roles:before {
  content: "\e9ae";
}
.icon-ActivityHistory:before {
  content: "\e9af";
}
.icon-ArrowNext:before {
  content: "\e9b0";
}
.icon-newunlock:before {
  content: "\e9b1";
}
.icon-AutoCompleter:before {
  content: "\e9b2";
}
.icon-CheckboxGroup:before {
  content: "\e9b3";
}
.icon-CSV:before {
  content: "\e9b4";
}
.icon-EmailMessage:before {
  content: "\e9b5";
}
.icon-EntryField:before {
  content: "\e9b6";
}
.icon-Favourite:before {
  content: "\e9b7";
}
.icon-HorizontalView:before {
  content: "\e9b8";
}
.icon-InlineEditTable:before {
  content: "\e9b9";
}
.icon-PDF:before {
  content: "\e9ba";
}
.icon-Print:before {
  content: "\e9bb";
}
.icon-QuestionHirarchy:before {
  content: "\e9bc";
}
.icon-Refresh:before {
  content: "\e9bd";
}
.icon-RelatedDatafield:before {
  content: "\e9be";
}
.icon-RichtextArea:before {
  content: "\e9bf";
}
.icon-SelectionList:before {
  content: "\e9c0";
}
.icon-Table:before {
  content: "\e9c1";
}
.icon-Task:before {
  content: "\e9c2";
}
.icon-VerticalView:before {
  content: "\e9c3";
}
.icon-Viewchange:before {
  content: "\e9c4";
}
.icon-ViewDown:before {
  content: "\e9c5";
}
.icon-ViewUp:before {
  content: "\e9c6";
}
.icon-dashboard:before {
  content: "\e9c7";
}
.icon-status_overdue:before {
  content: "\e9c8";
}
.icon-coaching:before {
  content: "\e9c9";
}
.icon-night:before {
  content: "\e9ca";
}
.icon-day:before {
  content: "\e9cb";
}
.icon-morning:before {
  content: "\e9cc";
}
.icon-status_assigned:before {
  content: "\e9cd";
}
.icon-status_bid_open:before {
  content: "\e9ce";
}
.icon-status_bid_close:before {
  content: "\e9cf";
}
.icon-control-room:before {
  content: "\e9d0";
}
.icon-evaluation:before {
  content: "\e9d1";
}
.icon-play-2:before {
  content: "\e9d2";
}
.icon-as-date-time:before {
  content: "\e9d3";
}
.icon-clipboard:before {
  content: "\e9d4";
}
.icon-clipboard-copy:before {
  content: "\e9d5";
}
.icon-clipboard-text:before {
  content: "\e9d6";
}
.icon-conversion:before {
  content: "\e9d7";
}
.icon-conversion-to-calendar:before {
  content: "\e9d8";
}
.icon-conversion-to-number:before {
  content: "\e9d9";
}
.icon-conversion-to-text:before {
  content: "\e9da";
}
.icon-file:before {
  content: "\e9db";
}
.icon-file-directory-copy:before {
  content: "\e9dc";
}
.icon-file-directory-create:before {
  content: "\e9dd";
}
.icon-file-directory-delete:before {
  content: "\e9de";
}
.icon-file-directory-exist:before {
  content: "\e9df";
}
.icon-file-directory-rename:before {
  content: "\e9e0";
}
.icon-file-file-copy:before {
  content: "\e9e1";
}
.icon-file-file-delete:before {
  content: "\e9e2";
}
.icon-file-file-exist:before {
  content: "\e9e3";
}
.icon-file-file-move:before {
  content: "\e9e4";
}
.icon-file-file-read-text:before {
  content: "\e9e5";
}
.icon-file-file-rename:before {
  content: "\e9e6";
}
.icon-math:before {
  content: "\e9e7";
}
.icon-math-divide:before {
  content: "\e9e8";
}
.icon-math-multiply:before {
  content: "\e9e9";
}
.icon-math-sum:before {
  content: "\e9ea";
}
.icon-math-minus:before {
  content: "\e9eb";
}
.icon-drag:before {
  content: "\e9ec";
}
.icon-tenants:before {
  content: "\e9ed";
}
.icon-stop:before {
  content: "\e9ee";
}
.icon-play:before {
  content: "\e9ef";
}
.icon-arrow-45:before {
  content: "\e9f0";
}
.icon-arrow45:before {
  content: "\e9f1";
}
.icon-team:before {
  content: "\e9f2";
}
.icon-type-list:before {
  content: "\e9f3";
}
.icon-type-assignment:before {
  content: "\e9f4";
}
.icon-type-boolean:before {
  content: "\e9f5";
}
.icon-type-decimal:before {
  content: "\e9f6";
}
.icon-type-number:before {
  content: "\e9f7";
}
.icon-helper-window:before {
  content: "\e9f8";
}
.icon-helper-mouse:before {
  content: "\e9f9";
}
.icon-helper-keyboard:before {
  content: "\e9fa";
}
.icon-good:before {
  content: "\e9fb";
}
.icon-bad:before {
  content: "\e9fc";
}
.icon-arrow-sidebar:before {
  content: "\e9fd";
}
.icon-math-median:before {
  content: "\e9fe";
}
.icon-math-deviation:before {
  content: "\e9ff";
}
.icon-math-sin:before {
  content: "\ea00";
}
.icon-math-cos:before {
  content: "\ea01";
}
.icon-conversion-to-decimal:before {
  content: "\ea02";
}
.icon-conversion-to-boolean:before {
  content: "\ea03";
}
.icon-as-text-lowercase:before {
  content: "\ea04";
}
.icon-as-text-uppercase:before {
  content: "\ea05";
}
.icon-file-directory-move:before {
  content: "\ea06";
}
.icon-zero-picker:before {
  content: "\ea07";
}
.icon-Paragraph:before {
  content: "\ea08";
}
.icon-status-bid-submitted:before {
  content: "\ea09";
}
.icon-status-challenged:before {
  content: "\ea0a";
}
.icon-agent-messaging:before {
  content: "\ea0b";
}
.icon-business-unit:before {
  content: "\ea0c";
}
.icon-custom-reports:before {
  content: "\ea0d";
}
.icon-data-download:before {
  content: "\ea0e";
}
.icon-data-points:before {
  content: "\ea0f";
}
.icon-notification-settings:before {
  content: "\ea10";
}
.icon-outbound:before {
  content: "\ea11";
}
.icon-pending-rules:before {
  content: "\ea12";
}
.icon-retention:before {
  content: "\ea13";
}
.icon-workforce-intelligence:before {
  content: "\ea14";
}
.icon-billing:before {
  content: "\ea15";
}
.icon-as-date:before {
  content: "\ea16";
}
.icon-data-service:before {
  content: "\ea17";
}
.icon-flag:before {
  content: "\ea18";
}
.icon-release-notes:before {
  content: "\ea19";
}
.icon-more-expand:before {
  content: "\ea1a";
}
.icon-share:before {
  content: "\ea1b";
}
.icon-hambrger:before {
  content: "\ea1c";
}
.icon-capture:before {
  content: "\ea1d";
}
.icon-capture-assets:before {
  content: "\ea1e";
}
.icon-screen-element:before {
  content: "\ea1f";
}
.icon-web-service:before {
  content: "\ea20";
}
.icon-lightning:before {
  content: "\ea21";
}
.icon-complex:before {
  content: "\ea22";
}
.icon-Sunburstgraphicview:before {
  content: "\ea23";
}
.icon-builtin:before {
  content: "\ea24";
}
.icon-camera:before {
  content: "\ea25";
}
.icon-marketplace:before {
  content: "\ea26";
}
.icon-scroll:before {
  content: "\ea27";
}
.icon-tree:before {
  content: "\ea28";
}
.icon-password:before {
  content: "\ea29";
}
.icon-tab:before {
  content: "\ea2a";
}
.icon-menu:before {
  content: "\ea2b";
}
.icon-button:before {
  content: "\ea2c";
}
.icon-Languages:before {
  content: "\ea2d";
}
.icon-WrittenCommunication:before {
  content: "\ea2e";
}
.icon-Accent:before {
  content: "\ea2f";
}
.icon-Home:before {
  content: "\ea30";
}
.icon-ocr:before {
  content: "\ea31";
}
.icon-windows:before {
  content: "\ea32";
}
.icon-partners:before {
  content: "\ea33";
}
.icon-screen-recorder:before {
  content: "\ea34";
}
.icon-AHT:before {
  content: "\ea35";
}
.icon-intraday:before {
  content: "\ea36";
}
.icon-status_readyforyou:before {
  content: "\ea37";
}
.icon-status_readyfor:before {
  content: "\ea38";
}
.icon-status_autoAcknowledge:before {
  content: "\ea39";
}
.icon-status_challenged:before {
  content: "\ea3a";
}
.icon-pin:before {
  content: "\ea3b";
}
.icon-empty-object:before {
  content: "\ea3c";
}
.icon-dashboard-2:before {
  content: "\ea3d";
}
.icon-New_window:before {
  content: "\ea3e";
}
.icon-mic:before {
  content: "\ea3f";
}
.icon-mute:before {
  content: "\ea40";
}
.icon-pause:before {
  content: "\ea41";
}
.icon-volume-low:before {
  content: "\ea42";
}
.icon-volume-med:before {
  content: "\ea43";
}
.icon-call-hold:before {
  content: "\ea44";
}
.icon-call-mask:before {
  content: "\ea45";
}
.icon-minus-three-sec:before {
  content: "\ea46";
}
.icon-plus-three-sec:before {
  content: "\ea47";
}
.icon-transfer:before {
  content: "\ea48";
}
.icon-tree-connector:before {
  content: "\ea49";
}
.icon-volume-full:before {
  content: "\ea4a";
}
.icon-call-direction:before {
  content: "\ea4b";
}
.icon-unknown-call:before {
  content: "\ea4c";
}
.icon-inbound-call:before {
  content: "\ea4d";
}
.icon-recording-policies:before {
  content: "\ea4e";
}
.icon-internal-call:before {
  content: "\ea4f";
}
.icon-asending:before {
  content: "\ea50";
}
.icon-Show:before {
  content: "\ea51";
}
.icon-hide:before {
  content: "\ea52";
}
.icon-Folder_lock:before {
  content: "\ea53";
}
.icon-Sub_category:before {
  content: "\ea54";
}
.icon-loops:before {
  content: "\ea55";
}
.icon-transition:before {
  content: "\ea56";
}
.icon-Previous:before {
  content: "\ea57";
}
.icon-group:before {
  content: "\ea58";
}
.icon-ungroup:before {
  content: "\ea59";
}
.icon-widget-resize:before {
  content: "\ea5a";
}
.icon-reset-size:before {
  content: "\ea5b";
}
.icon-location:before {
  content: "\ea5c";
}
.icon-open-link:before {
  content: "\ea5d";
}
.icon-Private:before {
  content: "\ea5e";
}
.icon-Public:before {
  content: "\ea5f";
}
.icon-data-layers:before {
  content: "\ea60";
}
.icon-partially-completed:before {
  content: "\ea61";
}
.icon-coming-soon:before {
  content: "\ea62";
}
.icon-just-added:before {
  content: "\ea63";
}
.icon-iterator:before {
  content: "\ea64";
}
.icon-policies:before {
  content: "\ea65";
}
.icon-solution-manager:before {
  content: "\ea66";
}
.icon-GoalHistory:before {
  content: "\ea67";
}
