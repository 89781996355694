.breeze {
  .p-button:disabled{
    border-color: #ced4da;
  }
  /* buttons */

  /* polyphil for outlines */
  .btn:focus, .btn:active:focus, .btn.active:focus {
    outline: none;
  }

  /* primary button */
  .btn {
    font-family: "Open Sans";
    font-size: 14px;
    border-radius: 3px;
    text-decoration: none;
    box-sizing: border-box;
    min-width: 40px;
    height: 40px;

    &:focus {
      outline: 0 !important;
      box-shadow: none;
    }
  }

  .btn.btn-primary {
    color: #fff;
    background-color: #007cbe;
    border-color: #007cbe;
    box-shadow: 0px 2px 0px 0px #1c639c;
  }

  .btn.btn-primary:hover, .btn-primary:focus:hover:not(:active) {
    background-color: #298fd8;
    border-color: #298fd8;
    box-shadow: 0px 2px 0px 0px #237cbb;
  }

  .btn.btn-primary:focus {
    background-color: #007cbe;
    border-color: #007cbe;
    box-shadow: 0px 2px 0px 0px #1c639c;
  }

  .btn.btn-primary:active {
    background-color: #006398;
    border-color: #006398;
    box-shadow: none; /* 0px 2px 0px 0px #006398;*/
  }

  /* secondary button */
  .btn.btn-secondary {
    color: #6b7984;
    background-color: #fff;
    border-color: #dae2e8;
    box-shadow: 0px 2px 0px 0px #dae2e8;
  }

  .btn.btn-secondary:hover, .btn-secondary:hover:focus:not(:active) {
    background-color: #fff;
    border-color: #6b8ea8;
    box-shadow: 0px 2px 0px 0px #6b8ea8;
  }

  .btn.btn-secondary:focus {
    background-color: #fff;
    border-color: #dae2e8;
    box-shadow: 0px 2px 0px 0px #dae2e8;
  }

  .btn.btn-secondary:active {
    background-color: #ecf2f6;
    border-color: #afc1cf;
    box-shadow: none;
  }

  /* disabled buttons */
  .btn.disabled,
  .btn[disabled],
  .btn.disabled:hover,
  .btn[disabled]:hover,
  .btn.disabled:focus,
  .btn[disabled]:focus,
  .btn.disabled:active,
  .btn[disabled]:active,
  .btn.disabled.active,
  .btn[disabled].active {
    background-color: #eeeeee !important;
    border-color: #dde4e9 !important;
    color: #697782 !important;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    box-shadow: 0px 1px 0px 0px #dae2e8 !important;
    cursor: default;
  }

  /* buttons sizes */
  .btn.btn-lg {
    padding: 8px 25px 6px;
    line-height: 16px;
    margin: 0 10px;
  }

  .btn.btn-sm {
    padding: 8px 25px 6px;
    font-size: 14px;
    line-height: 14px;
    margin: 0 6px;
  }

  .btn.btn-xs {
    padding: 4px 15px 2px;
    font-size: 11px;
    line-height: 10px;
    margin: 0 4px;
  }


  .btn {
    transition: color 0.05s ease-in-out, background-color 0.05s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .button-centered {
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      margin: 0px;
    }
  }

  .btn-group {
    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
    }
  }

  .btn-secondary:not(:disabled):not(.disabled):active:focus,
  .btn-secondary:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0px 2px 0px 0px #dae2e8;
  }

  .btn-secondary:not(:disabled):not(.disabled):active,
  .btn-secondary:not(:disabled):not(.disabled).active {
    color: rgb(86, 96, 105);
    //box-shadow: inset 2px 2px 2px rgba(175,193,207,0.4);
    background-color: rgb(215, 231, 241);
    border-color: rgb(175, 193, 207);
  }


  .toolbar-container .btn {
    box-shadow: 0px 1px 0px 0px #dae2e8 !important;
  }
  .btn-rect.btn-small{
    width:35px;
    height:35px;
  }
}
