wfm-esp-root, api-credential {
  .app-wrapper.content-wrapper, .p-toast-esp-root, .p-toast-api-creds {
    .p-toast.p-toast-bottom-right {
      right: 55px;
    }

    .p-toast {
      width:auto;

      .p-toast-message {
        border-radius: 1px;

        .p-icon-wrapper {
          display: none;
        }

        .p-toast-icon-close {
          color: white;
          font-family: 'icomoon';
          width: auto;

          &:before {
            content: '\e936';
            font-size: 1rem;
            vertical-align: middle;
            color: white;
          }

          &:hover {
            color: white;
            opacity: 1;
            text-decoration: none;
          }

          &:focus {
            color: white;
            opacity: 1;
            text-decoration: none;
          }
        }

        .p-toast-icon-close:hover{
          background:none;
        }
      }

      .p-toast-message-content {
        .p-toast-detail{
          margin: 2px 0 0 0;
        }
      }
      .p-toast-message-text-content {
        padding: .5em;

        .p-toast-summary {
          display: none;
        }
      }
    }

    .p-toast-message.p-toast-message-success {
      width: 342px;
      background: #23762D;
      color: white;
      border: none;

      .p-toast-message-icon {
        top: 8px;
        margin-top: 0;
        color: white;
        line-height: 18px;
        font-family: 'icomoon' !important;

        &:before {
          content: '\e927';
          font-size: 1.25rem;
        }
      }

      .p-toast-message-content {
        font-size: 14px;
      }
    }

    .p-toast-message.p-toast-message-error {
      width: 342px;
      background: #c43535;
      color: white;
      border: none;

      .p-toast-message-icon {
        top: 8px;
        margin-top: 0;
        color: white;
        line-height: 18px;
        font-family: 'icomoon' !important;

        &:before {
          content: '\e95c';
          font-size: 1.25rem;
        }
      }

      .p-toast-message-content {
        font-size: 14px;
        padding-right: 1.5em;
      }
    }
  }
}
