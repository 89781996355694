.breeze{
  .p-tooltip.validation-error{
    max-width: 250px;
  }
  .p-tooltip.validation-error .p-tooltip-arrow{
    /*  border: 1px solid #d0d2d3;
      width: 10px;
      height: 10px;
      transform: rotate(45deg);
      background: white;
      border-width: 1px !important;*/
  }
   .validation-error.bs-popover-bottom.bs-popover-bottom-left{
    display: none;

  }
  .validation-error .message-text p{
    margin-bottom: 0;
  }
  .validation-error .message-text{
    font-family: "OpenSans", Arial, sans-serif;
    font-size: 12px;
    max-width: 300px;
    color: #2e2e2e;
    text-align: left;
    text-decoration: none;
    line-height: 15px;
  }
  .validation-message+.push-right{
    margin-left:22px;
  }
}
