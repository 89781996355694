.breeze{
  font-family: "Open Sans", Arial, sans-serif;
  color:#212e36;
}

/* breeze typography exposition */
.breeze .text-bigger,
.breeze .text-big,
.breeze .text-normal,
.breeze .text-ormal-semibold,
.breeze .text-secondary{
  font-family: "OpenSans", Arial, sans-serif;
  color:#212e36;
}

/* Hierarchic Titles */
.breeze .app-title{
  font-family: "Open Sans Light", Arial, sans-serif;
  color:#fff;
  font-size:18px;
  text-transform: uppercase;
}
.breeze .page-title{
  font-family: "Open Sans", Arial, sans-serif;
  color:#2e2e2e;
  font-size:18px;
}
.breeze .portlet-title{
  font-family: "Open Sans Semi Bold", Arial, sans-serif;
  color:#5b788e;
  font-size:16px;
}
.breeze .section-title{
  font-family: "Open Sans Semi Bold", Arial, sans-serif;
  color:#2e2e2e;
  font-size:14px;
}
/* end of the basics title hierachy */


/* other texts of breeze */
.breeze .onscreen-notice-big{
  font-family: "OpenSansLight", Arial, sans-serif;
  color:#2e2e2e;
  font-size:24px;
}

.breeze .text-normal-semibold{
  font-family: "OpenSansSemiBold", Arial, sans-serif;
  font-size:14px;
}
.breeze .text-secondary{
  font-size:12px;
}
/* end of breeze typography exposition */


.breeze .primary-text{
  font-family: "OpenSans", Arial, sans-serif;
  color:#2e2e2e;
  font-size:14px;
}

.breeze .primary-text-gray{
  font-family: "OpenSans", Arial, sans-serif;
  color:#707070;
  font-size:14px;
}
.breeze .link-text{
  font-family: "OpenSans", Arial, sans-serif;
  color:#007cbe;
  font-size:14px;
}
.breeze .link-text:hover{
  text-decoration: underline;
  cursor: pointer;
}
.breeze .input-label{
  font-family: "OpenSansBold", Arial, sans-serif;
  color:#8c95a5;
  font-size:11px;
  text-transform: uppercase;
}

.breeze .table-label{
  font-family: "OpenSansBold", Arial, sans-serif;
  color:#8c95a5;
  font-size:11px;
  text-transform: uppercase;
}
