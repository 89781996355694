/* native progressbar */
.b-p-container{
  display: flex;
  align-items: center;
}
.b-p-progress{
  width: 600px;
  height: 15px;
  border: solid 1px #d0d5d9;
  display: flex;
  box-sizing: border-box;
}
.b-progress-bar{
  background-color: #237bc2;
  display: inline-block;
  height: 13px;
  box-sizing: border-box;
}
.b-p-label{
  color: #707070;
  font-size: 16px;
  font-weight: bold;
  margin-left:5px;
}
.b-p-textlabel{
  color:#707070;
  font-size:12px;
  margin-right:5px;
}
