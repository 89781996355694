.inputs-table{
  border-collapse: separate;
}

.inputs-table thead th{
  padding-bottom: 8px;
  font-weight: bold;
}
.inputs-table tbody td{
  padding-right: 16px;
}
