.modal-header{
  // padding: 12px 20px 11px 20px;
  font-family: "Open Sans", Arial, sans-serif;
  border-bottom: 1px solid #DAE2E8;
  border-radius: 0;
}
.modal-body{
  min-height: 90px;
  background-color: #e9f1f5;
  padding: 20px;
  margin-left: 1px;
  max-width: 550px;
}
.modal-header .close {
  margin-top: 0px;
  opacity: .8;
  color: #afc1cf;
  padding: 0rem;
  margin: 0rem 0rem 0rem auto;

}
.modal-title{
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  color: #404040;
  margin-left: 5px;
}
.modal-header .close:hover, .modal-header .close:focus {
  opacity: 1;
  color: #afc1cf;
}
// .modal-content {
//     // border-radius: 0;
//     max-width:420px;
//     box-shadow:0 0 35px rgba(0,0,0,.3);
//     border:solid 1px #d0d5d9;
//     border-radius: 0;
// }
.modal-footer{
  padding: 12px 8px;
  border-top:solid 1px #d0d5d9;
}

/* error message */
.b-error .modal-header {
  border-bottom: solid 1px #fa3f40;
}
.b-error .ib-alert{
  vertical-align: middle;
  display: inline-block;
  margin-right: 10px;
}

/* warning message */
.b-warning .modal-header {
  border-bottom: solid 1px #f15d22;
}
.b-warning .ib-warning{
  vertical-align: middle;
  display: inline-block;
  margin-right: 10px;
}
/* info message */
.b-info .modal-header {
  border-bottom: solid 1px #43acd9;
}
.b-info .ib-info{
  vertical-align: middle;
  display: inline-block;
  margin-right: 10px;
}

.icon-Close {
  font-family: 'icomoon' !important;
  font-weight: lighter;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  color: #93A4AA;

}

input.form-control.form-control-inline {
  display: inline-block;
  width: 5em;
  margin-right: .5em;
}

.input-label{
  font-family: "Open Sans", Arial, sans-serif;
  color:#8c95a5;
  font-size:11px;
  font-weight: bold;
  text-transform: uppercase;
}

.error-message {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #d82132;
}

::ng-deep .wfm-modal  {
  .modal-content{
    border-radius: 0;
    width: 540px;
    border: 1px solid #DAE2E8;
  }

  .modal-header {
    height: 80px;
    align-items: center;
    border-bottom: 1px solid #DAE2E8;
  }
  .modal-footer {
    height: 80px;
    border-top:solid 1px #d0d5d9;
  }

}
