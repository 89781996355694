.radio {
  padding-left: 7px;
}
.radio label {
  position: relative;
  padding-left: 5px;
}
.radio label:before {
  content: "";
  position: absolute;
  width: 17px;
  height: 17px;
  left: 0;
  margin-left: -20px;
  border: 1px solid #cccccc;
  border-radius: 50%;
  background-color: #fff;
  transition: border 0.15s ease-in-out;
  margin-top: 2px;
}
.radio label:after {
  position: absolute;
  content: " ";
  width: 7px;
  height: 7px;
  left: 5px;
  top: 5px;
  margin-left: -20px;
  margin-top: 2px;
  border-radius: 50%;
  background-color: #237bc2;
  transform: scale(0, 0);
  transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
}
.radio input[type="radio"] {
  opacity: 0;
  z-index: 1;
}
.radio input[type="radio"] + label:hover:before {
  border-color:#237bc2;
}
.radio input[type="radio"]:focus + label:before {
  outline: 0;
}
.radio input[type="radio"]:checked + label:after {
  transform: scale(1, 1);
}
.radio input[type="radio"]:disabled + label {
  opacity: 0.65;
  cursor:default;
}
.radio input[type="radio"]:disabled + label:hover:before{
  border-color: #ccc;
}
.radio input[type="radio"]:disabled + label:before {
  cursor: not-allowed;
}
.radio input[type="radio"]:checked:disabled + label:after {
  background-color:#C7D3D9;
}
.radio input[type=radio],
.radio-inline input[type=radio]{
  margin-left:0;
}
/* view state */
.radio-view{
  padding:0;
  list-style: none;
  line-height: 22px
}
.radio-view.selected{
  font-family: "OpenSans";
  font-weight: 600;
}
.radio-view.selected:before{
  content:" ";
  display:inline-block;
  width:6px;
  height:6px;
  border-radius:50%;
  background-color:#007cbe;
  vertical-align: middle;
  margin-right: 4px;
  margin-bottom: 2px;
}
.radio-view.not-selected{
  margin-left:10px;
}
