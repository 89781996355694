.breeze {
  .p-tooltip {
    z-index: 1050 !important;
    max-width: 35em;
  }

  .p-tooltip-right{
    margin-left: 5px;
    margin-top: -1px;
  }
  .p-tooltip-left{
    margin-right: 5px;
    margin-top: -3px;
  }

  .p-tooltip-bright {
    .p-tooltip-text {
      font-family: "OpenSans", Arial, sans-serif;
      font-size: 13px;
      box-shadow: 0px 0px 3px #d0d2d3;
      max-width: 300px;
      padding: 12px;
      color: #2e2e2e;
      text-align: left;
      text-decoration: none;
      background-color: white;
      border-radius: 0px;
      border: 1px solid #d0d2d3;
      line-height: 15px;
    }

    .p-tooltip-arrow {
      border: 1px solid #d0d2d3;
      width: 10px;
      height: 10px;
      transform: rotate(45deg);
      background: white;
      border-width: 1px !important;
    }

    &.p-tooltip-right .p-tooltip-arrow {
      border-top-color: #d0d2d3;
      border-right: none;
      border-top: none;
      left: -1px;
    }

    &.p-tooltip-left .p-tooltip-arrow {
      border-left-color: #d0d2d3;
      border-bottom: none;
      border-left: none;
    }

    &.p-tooltip-top .p-tooltip-arrow {
      border-top-color: #d0d2d3;
      border-left: none;
      border-top: none;
    }

    &.p-tooltip-bottom .p-tooltip-arrow {
      border-bottom: none;
      border-right: none;
    }
  }

  .popover-tooltip{
    font-family: "OpenSans", Arial, sans-serif;
    font-size: 13px;
  }
  .p-tooltip .p-tooltip-text{
    white-space: nowrap;
    word-break: normal;
  }
}
