.toolbar-container{
  background-color: #F7F8FA;
  padding: 10px;
  margin: 0;
  height: 60px;
  border-top: 1px solid #c1bebe;
  border-left: 1px solid #c1bebe;
  border-right: 1px solid #c1bebe;
  display: flex;
}
.toolbar-container.vertical-items{
  height: 100px;
  padding-left: 24px;
  padding-top:20px;
  border-bottom: 1px solid #c1bebe;
}
.toolbar-label{
  margin-right:8px;
  color:#2E2E2E;
  flex: 0 0 auto;
}

.toolbar-vertical-item{
  display: flex;
  flex-direction: column;
  min-width: 200px;
  margin-right: 30px;

  .toolbar-label{
    margin-bottom: 7px;
    font: normal normal 600 11px/11px Open Sans;
    letter-spacing: 0px;
    color: #577085;
  }
}

