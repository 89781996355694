.breeze .app-content{
  .p-inputswitch-checked .p-inputswitch-slider{
    background: rgb(249,163,70) !important;
  }

  .p-inputswitch{
    background: transparent;
    height: 20px;
    width: 35px;
    transition: all 0.3s linear;
  }
  .p-inputswitch.p-focus .p-inputswitch-slider{
    box-shadow: none !important;
  }
  .p-inputswitch-slider{
    background: rgb(0,124,190) !important;
    transition: .3s;
    border-radius: 30px;


  }
  .p-inputswitch-slider:before{
    background: white;
    height: 18px;
    width: 18px;
    left: 1px;
    bottom: 1px;
    border-radius: 50%;
    transition: .3s;
    margin-top: -9px;
  }
  .p-inputswitch-checked .p-inputswitch-slider:before{
    transform: translateX(15px);
  }
}
