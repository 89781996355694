$breeze-default-border-color: #d6dee5;
$breeze-focus-border-color: #237bc2;
$breeze-selected-color: #237bc2;
$breeze-selected-hover-color: #cfe6f3;
$breeze-icon-color: #62aee7;

.breeze {
  p-calendar {
    font-family: "OpenSans", Arial, sans-serif;
    font-size: 14px;
  }

  p-calendar {
    .ui-calendar:hover input:not([disabled]) {
      border-color: $breeze-focus-border-color;
    }

    .ui-calendar.ui-calendar-w-btn input:focus {
      border-color: $breeze-focus-border-color;
    }

    .ui-calendar.ui-calendar-w-btn input {
      border-radius: 0;
      box-shadow: none;
    }

    .ui-datepicker th {
      color: #666;
      font-weight: normal;
      font-size: .8em;
      text-transform: uppercase;
    }

    .ui-datepicker .ui-datepicker-calendar thead th {
      background: white;
    }

    .ui-datepicker .ui-datepicker-calendar td {
      border: none;
    }

    .ui-datepicker .ui-datepicker-header {
      background: white;
    }

    .ui-datepicker .ui-datepicker-calendar td:not(.ui-state-disabled) a:hover {
      background: $breeze-selected-color;
      color: white;
      cursor: pointer;
    }

    .ui-datepicker table.ui-datepicker-calendar td:not(.ui-state-disabled) a.ui-state-active {
      background: $breeze-selected-color;
      color: white;
    }

    .ui-datepicker table.ui-datepicker-calendar td:not(.ui-state-disabled) a.ui-state-active:hover {
      background: $breeze-selected-hover-color;
      color: black;
    }

    .ui-widget-content {
      box-shadow: none;
      border: none;
    }

    .ui-widget-content.ui-datepicker-group {
      border: 1px solid $breeze-default-border-color;
      padding-right: 1px;
    }

    .ui-calendar .ui-calendar-button {
      position: absolute;
      border-radius: 0;
    }

    .ui-datepicker .ui-datepicker-title {
      min-width: 200px;
    }

    .ui-datepicker .ui-datepicker-title select {
      background: white;
      border-radius: 0;
      border: 1px solid $breeze-default-border-color;

    }

    .ui-widget-header .ui-button, .ui-widget-content .ui-button, .ui-widget.ui-button, .ui-button {
      background: white;
      border-bottom: 1px solid $breeze-default-border-color;
      border-right: 1px solid $breeze-default-border-color;
      border-top: 1px solid $breeze-default-border-color;
      color: $breeze-icon-color;
      -webkit-transition: .2s;
      -moz-transition: .2s;
      transition: .2s;
      font-size: 16px;
    }

    .ui-calendar:hover .ui-button:not([disabled]),
    .ui-inputwrapper-focus .ui-button:not([disabled]),
    input:focus + .ui-button {
      border-color: $breeze-focus-border-color;
      background: $breeze-selected-color;
      color: white;
    }

    .ui-state-disabled, .ui-widget:disabled {
      opacity: 0.6;
    }
  }

  .bs-datepicker-body table.years tbody tr td span, .bs-datepicker-body table.months tbody tr td span {
    height: 62px !important;
  }

  p-calendar.range-picker:hover:not(.disabled) {
      border-color: #237bc2;

  }
  .range-picker {

    font-family: "Open Sans", Arial, sans-serif;
    min-width: 215px;
    border: 1px solid #ced4da;



    .p-link {
      font-family: "Open Sans", Arial, sans-serif;
      font-size: 14px;
    }

    .p-calendar {
      width: 100%;
      height: 34px;
    }

    ::placeholder {
      color: #999;
    }

    .p-datepicker-trigger {
      border-radius: 0;
      background: white;
      color: #007CBE;
      border: none;
    }

    .p-datepicker-trigger:hover {
      background: white;
      color: #007CBE;
    }

    .p-datepicker-trigger .p-button-icon:before {
      content: "\e918";
      color: #007CBE;
      display: inline-block;
      font-family: 'icomoon';
      font-size: 12px;
      text-rendering: auto;
    }

    .p-inputtext {
      font-size: 13px;
      border-radius: 0;
      padding-top: 9px;
      line-height: 17px;
      border: none;
    }

    .p-datepicker-header .p-datepicker-title .p-datepicker-year,
    .p-datepicker-header .p-datepicker-title .p-datepicker-month {
      font-weight: 400;
    }

    table {
      margin: 0;
      width: auto;
    }

    table th {
      color: #5B7C8C;
      font-size: 13px;
      font-weight: 600;
      text-transform: uppercase;
      vertical-align: middle;
      border-bottom: 1px solid #d0d2d3;
      text-align: center;
      max-width: 35px;
    }

    table tbody td.p-datepicker-today .p-highlight {
      background: #D9EBF5;
      color: #2e2e2e;
    }

    table tbody td .p-highlight {
      background: #D9EBF5;
      color: #2e2e2e;
    }

    table tbody td {
      color: #54708b;
      text-align: center;
      position: relative;
      padding: 0;
      font-size: 13px;
      max-width: 35px;
      max-height: 35px;
    }

    table tbody td span {
      width: 35px;
      height: 35px;
      border-radius: 0;
    }

    &.p-datepicker .p-datepicker-header {
      height: 45px;
      background-color: white;
      padding: 0;
      border-bottom: 1px solid #d0d2d3;
      font-weight: 400;
      font-size: 14px;
    }
    *{
      box-shadow: none !important;
    }
  }
}
