.embedded-warning .fa{
  color: orange;
  margin-right: 2px;
  font-size: 15px;
}

.embedded-info .icon-info{
  color: #2d7eaa;
  margin-right: 3px;
  font-size: 18px;
  vertical-align: sub;
}

.embedded-warning{
  background: white;
  border: 1px solid orange;
  border-radius: 4px;
  padding: 9px;
}
.embedded-info.borderless{
  background: transparent;
  border: none;
}
.embedded-info{
  background: white;
  border: 1px solid #2d7eaa;
  border-radius: 4px;
  padding: 9px;
  font-size: 14px;
}

.info-tooltip{
  color: #3784ae;
  font-size: 16px;
  height: 30px;
  width: 30px;
  /* background: red; */
  display: inline-block;
  vertical-align: middle;
  line-height: 30px;
  text-align: center;
  margin-left: 0;
}
.embedded-error{
  background: white;
  border: 1px solid red;
  border-radius: 4px;
  padding: 9px;
}

.embedded-error .fa{
  color: red;
  margin-right: 2px;
  font-size: 15px;
}
